<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      :title="edit? '编辑':'新建'"
      :track="{
        name_zh: `基础数据/人员管理/岗位管理/弹窗-${edit? '编辑':'新建'}`,
        trackName: $route.path
      }"
      @close="handleClose"
    >
      <nh-dialog-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <nh-dialog-form-item label="岗位名称：" prop="positionName">
          <el-input v-model="form.positionName" />
        </nh-dialog-form-item>
      </nh-dialog-form>
      <template #footer>
        <div>
          <nh-button
            @click="close"
          >
            取 消
          </nh-button>
          <nh-button
            type="primary"
            :loading="loading.submitForm"
            @click="submitForm"
          >
            确 定
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import { positionInsert, positionUpdate } from '../api';

export default {
  name: 'EditDialog',
  mixins: [loadingMixin],
  emits: ['headleSuccess'],
  data() {
    return {
      edit: false,
      dialogVisible: false,
      loading: {
        submitForm: false,
      },
      form: {
        positionName: null,
      },
      rules: {
        positionName: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(data) {
      this.dialogVisible = true;
      this.edit = !!data;
      this.$nextTick(() => {
        if (data) {
          this.form.id = data.id;
          this.form.positionName = data.positionName;
        }
      });
    },
    async submitForm() {
      await this.$refs.ruleForm.validate();
      const data = { ...this.form };
      if (!this.edit) {
        await positionInsert(data);
      } else {
        await positionUpdate(data);
      }
      this.$message({
        type: 'success',
        message: `${this.edit ? '修改' : '添加'}成功`,
      });
      this.$emit('headleSuccess');
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
