import { FormType } from '@/constant/form';

const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '岗位名称',
  prop: 'positionName',
}, {
  label: '用户',
  prop: 'tenantName',
}, {
  label: '编辑人',
  prop: 'createUserName',
}, {
  label: '编辑时间',
  prop: 'updateTime',
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 120,
}];
export { TABLECOLUMN };
export const serchFields = [
  {
    label: '岗位名称',
    prop: 'positionName',
    component: FormType.INPUT,
  },
];
