import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 岗位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11278
 */
export function getPositionPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/position/page', data, { params });
}
/**
 * @description: 岗位管理 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11276
 */
export function positionDelete(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_management_system/position/delete?id=${id}`);
}
/**
 * @description: 岗位管理 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11276
 */
export function getPosition(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_management_system/position/get?id=${id}`);
}
/**
 * @description: 岗位管理 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11272
 */
export function positionUpdate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/position/update', data);
}
/**
 * @description: 岗位管理 新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11270
 */
export function positionInsert(data) {
  return wmsPlusHttp.post('/warehouse_management_system/position/insert', data);
}
