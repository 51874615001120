<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/人员管理/岗位管理/[新建]'
          }"
          @click="headleAddPost"
        >
          新建
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="headleEdit(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="headleRemove(row)">
          删除
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <edit-dialog ref="childEdit" @headleSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import EditDialog from './components/editDialog.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import { getPositionPage, positionDelete } from './api';

export default {
  name: 'PisitionManageMent',
  components: {
    EditDialog,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      formData: {},
      loading: {
        getTableData: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getPositionPage(pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
        updateTime: moment(item.beginWorkTime).format('YYYY/MM/DD HH:mm:SS'),
      }));
      this.pagination.total = resp.total;
    },
    headleAddPost() {
      this.$refs.childEdit.init();
    },
    headleEdit(row) {
      this.$refs.childEdit.init(row);
    },
    async headleRemove(row) {
      const params = {
        id: row.id,
      };
      await positionDelete(params);
      this.$message({
        type: 'success',
        message: '删除成功!',
      });
      this.getTableData();
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}

.text-btn {
  cursor: pointer;
  color: #409eff;
}

.text-btn:not(:first-of-type) {
  padding-left: 8px;
}
</style>
